import { makeStyles, useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import React, { useContext, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import clsx from 'clsx';
import CookieConsent from 'react-cookie-consent';
import AppContext from '../AppContext';
import Suspense from '../suspense/Suspense';
import ToolbarLayout from './ToolbarLayout';
import FooterLayout from './FooterLayout';
import Message from '../message';
import Backdrop from '../Backdrop';
import Alert from '../Alert';
import NavbarWrapperLayout from './NavbarWrapperLayout';
import { getStaticContentCats } from '../../store/Streaming';

const useStyles = makeStyles(() => ({
  root: {},
  contentWrapper: {
    backgroundColor: '#ffffff',
  },
}));

const Layout1 = (props) => {
  const config = useSelector((state) => state.settings.current.layout.config);
  const alertData = useSelector(({ backdrop }) => backdrop.alertData);
  const staticContentCats = useSelector(
    ({ Streaming }) => Streaming.staticContentCats
  );
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const { routes } = appContext;
  const classes = useStyles(props);
  const theme = useTheme();
  const { children } = props;

  React.useEffect(() => {
    let isMounted = true;
    async function fetchStaticContentCats() {
      await dispatch(getStaticContentCats());
    }
    if (staticContentCats.length === 0 && isMounted) {
      fetchStaticContentCats();
    }
    return () => {
      isMounted = false;
    };
  }, [dispatch, staticContentCats.length]);

  return (
    <div
      id="layout"
      className={clsx(classes.root, config.mode, `scroll-${config.scroll}`)}
    >
      <div className={classes.wrapper}>
        {config.navbar.display && <NavbarWrapperLayout />}
        <div className={classes.contentWrapper}>
          {config.toolbar.display && (
            <Hidden xsDown>
              <ToolbarLayout />
            </Hidden>
          )}

          <div className={classes.content}>
            <Suspense>{renderRoutes(routes)}</Suspense>

            {children}
          </div>

          {config.footer.display && <FooterLayout />}
        </div>
      </div>
      <Message />
      <Backdrop />
      {alertData.open && <Alert />}
      <CookieConsent
        location="bottom"
        buttonText="Accept Cookies"
        enableDeclineButton
        declineButtonText="Deny Cookies"
        style={{ background: '#2B373B', fontSize: '12px', zIndex: 1200 }}
        buttonStyle={{
          background: theme.palette.primary.main,
          color: '#ffffff',
          fontSize: '12px',
          margin: 15,
        }}
        declineButtonStyle={{
          background: theme.palette.primary.main,
          color: '#ffffff',
          fontSize: '12px',
          margin: 15,
        }}
        contentStyle={{
          margin: 15,
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
};

export default memo(Layout1);
