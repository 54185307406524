import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useSelector, useDispatch } from 'react-redux';
import { clearAlert } from '../../store/backdropSlice';

export default function AlertDialog() {
  const dispatch = useDispatch();
  const alertData = useSelector((state) => state.backdrop.alertData);

  const handleClose = () => {
    dispatch(clearAlert());
  };

  return (
    <div>
      <Dialog
        open={alertData.open}
        onClose={handleClose}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="baseline"
            spacing={0}
          >
            <Grid item>
              <Box>
                {alertData.variant === 'success' ? (
                  <CheckCircleIcon color="primary" />
                ) : (
                  <ErrorIcon color="primary" />
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: 'center' }}
          >
            {alertData.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            fullWidth
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
