import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { setUserData } from '../store/auth/store/userSlice';
import { fetchConsumer, fetchCoach } from '../store/profile/profileSlice';
import SplashScreen from './splash/SplashScreen';
import history from '../configurations/@history';

const AuthComp = (props) => {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  const fieldList = {
    displayName: '',
    photoURL: '',
    email: '',
    user_type: '',
    date_of_birth: null,
    gender: '',
    country: '',
    given_name: '',
    family_name: '',
    specialization: '',
    played_under: '',
    description: '',
    certification: '',
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authCheck = () =>
    new Promise((resolve) => {
      Auth.currentAuthenticatedUser()
        .then(async (res) => {
          const identities = res.attributes.identities
            ? JSON.parse(res.attributes.identities)
            : null;
          let photoURL = null;
          if (identities && identities[0].providerName === 'Google') {
            photoURL = res.attributes.picture;
          } else if (identities && identities[0].providerName === 'Facebook') {
            const picture = JSON.parse(res.attributes.picture);
            photoURL = picture.data.url;
          }

          if (Number(res.attributes['custom:user_type'])) {
            const userextData =
              // eslint-disable-next-line no-nested-ternary
              Number(res.attributes['custom:user_type']) === 2
                ? await dispatch(fetchConsumer())
                : Number(res.attributes['custom:user_type']) === 6
                ? await dispatch(fetchCoach())
                : {};
            const role =
              // eslint-disable-next-line no-nested-ternary
              Number(res.attributes['custom:user_type']) === 2
                ? 'player'
                : Number(res.attributes['custom:user_type']) === 6
                ? 'coach'
                : 'user';
            const userData = {
              role: [role], // guest
              data: {
                ...fieldList,
                displayName: res.attributes.given_name || res.attributes.name,
                photoURL,
                email: res.attributes.email,
                given_name: res.attributes.given_name,
                family_name: res.attributes.family_name,
                gender: res.attributes.gender,
                date_of_birth: res.attributes.birthdate,
                country: res.attributes['custom:country'],
                user_type: Number(res.attributes['custom:user_type']),
                ...userextData,
              },
              redirectUrl: '/',
              loginStatus: true,
            };
            delete userData.data.user;
            delete userData.data.first_name;
            delete userData.data.last_name;
            dispatch(setUserData(userData));
            setWaitAuthCheck(false);
            resolve();
            // history.push(userData.redirectUrl);  // **important** enable this statement to navigate to home page on hard reload
          } else {
            const userData = {
              role: ['user'], // guest
              data: {
                ...fieldList,
                displayName: res.attributes.given_name || res.attributes.name,
                photoURL,
                email: res.attributes.email,
                given_name: res.attributes.given_name,
                family_name: res.attributes.family_name
                  ? res.attributes.family_name
                  : '',
              },
              redirectUrl: '/profile',
              loginStatus: true,
            };
            dispatch(setUserData(userData));
            setWaitAuthCheck(false);
            resolve();
            history.push(userData.redirectUrl);
          }
        })
        .catch(() => {
          const userData = {
            role: [], // guest
            data: fieldList,
            redirectUrl: '/',
            loginStatus: false,
          };
          dispatch(setUserData(userData));
          setWaitAuthCheck(false);
          resolve();
        });

      return Promise.resolve();
    });

  useEffect(() => {
    authCheck();
  }, [authCheck]);

  const { children } = props;

  return waitAuthCheck ? <SplashScreen /> : <>{children}</>;
};

export default AuthComp;
