import { combineReducers } from '@reduxjs/toolkit';
import loading from './loaderSlice';
import settings from './settingsSlice';
import backdrop from './backdropSlice';
import auth from './auth/store';
import message from './messageSlice';
import navbar from './navbarSlice';
import profile from './profile/profileSlice';
import Streaming from './Streaming';

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    settings,
    loading,
    message,
    backdrop,
    navbar,
    profile,
    Streaming,
    ...asyncReducers,
  });

export default createReducer;
