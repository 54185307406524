import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { setInitialSettings } from '../../settingsSlice';
import history from '../../../configurations/@history';
// import { loginDefault } from './loginSlice';

const initialState = {
  role: [], // guest
  data: {
    displayName: '',
    photoURL: '',
    email: '',
    user_type: '',
    date_of_birth: null,
    gender: '',
    country: '',
    given_name: '',
    family_name: '',
    specialization: '',
    played_under: '',
    description: '',
    certification: '',
  },
  loginStatus: false,
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
    userLoggedOut: (state) => ({ ...state, ...initialState }),
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export const logoutUser = () => async (dispatch) => {
  localStorage.clear();

  return Auth.signOut({ global: true })
    .then(() => {
      const user = {
        role: [], // guest
        data: initialState.data,
        redirectUrl: '/',
        loginStatus: false,
      };
      dispatch(setInitialSettings());
      dispatch(userLoggedOut());
      if (user.redirectUrl) {
        history.location.state = {
          redirectUrl: user.redirectUrl,
        };
      }
      history.push(user.redirectUrl);
      window.location.reload();
    })
    .catch(() => {
      // console.log(err);
    });
};

export const setUserData = (user) => async (dispatch) => {
  if (user.redirectUrl) {
    history.location.state = {
      redirectUrl: user.redirectUrl,
    };
  }

  dispatch(setUser(user));
};

export default userSlice.reducer;
