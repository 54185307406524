const awsConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_IDENTITIPOOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENTID,
    // authenticationFlowType: 'CUSTOM_AUTH'
  },
  Analytics: {
    disabled: true,
  },
  oauth: {
    domain: process.env.REACT_APP_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn:
      window.location.hostname === 'localhost'
        ? 'http://localhost:3000/signin/'
        : 'https://www.crickingdom.ai/',
    redirectSignOut:
      window.location.hostname === 'localhost'
        ? 'http://localhost:3000/signin/'
        : 'https://www.crickingdom.ai/',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default awsConfig;
