import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from '../../configurations/@lodash';
import { setDefaultSettings } from '../../store/settingsSlice';

const NavbarFoldedToggleButton = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.current);

  const { className, children } = props;

  return (
    <IconButton
      className={className}
      onClick={() => {
        dispatch(
          setDefaultSettings(
            _.set(
              {},
              'layout.config.navbar.folded',
              !settings.layout.config.navbar.folded
            )
          )
        );
      }}
      color="inherit"
    >
      {children}
    </IconButton>
  );
};

NavbarFoldedToggleButton.defaultProps = {
  children: <Icon>menu</Icon>,
};

export default NavbarFoldedToggleButton;
