import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { getReviewers } from '../store/Streaming';
import ProfileImage from '../assets/profile1.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export default function ReviewersAutocomplete({
  reviewers,
  handleReviewersChange,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [inputSearch, setInputSearch] = React.useState('');
  const debounceOnChange = React.useMemo(
    () =>
      debounce((value) => {
        setInputSearch(value);
      }, 1000),
    []
  );

  React.useEffect(() => {
    if (inputSearch === '') {
      return false;
    }
    const active = true;
    setLoading(true);
    (async () => {
      const response = await dispatch(getReviewers(inputSearch));
      if (active) {
        if (response != null) {
          setOptions(response.results);
        }
        setLoading(false);
      }
    })();
    return true;
  }, [inputSearch, dispatch]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.trim();
    setInputValue(searchValue);
    if (searchValue.length >= 3) {
      debounceOnChange(searchValue);
    }
  };

  const handleChange = (selectedListItems) => {
    setInputValue('');
    handleReviewersChange(selectedListItems);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id="controlled-demo"
        multiple
        options={options}
        value={reviewers}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        autoComplete
        loading={loading}
        inputValue={inputValue}
        includeInputInList
        onChange={(event, selectedListItems) => handleChange(selectedListItems)}
        getOptionLabel={(option) =>
          `${option.user.first_name} ${option.user.last_name}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Reviewers"
            onChange={(event) => handleSearch(event)}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              avatar={<Avatar alt="Profile pic" src={ProfileImage} />}
              label={`${option.user.first_name} ${option.user.last_name}`}
            />
          ))
        }
        getOptionSelected={(option, value) => option.user.id === value.user.id}
        renderOption={(option) => (
          <ListItem alignItems="flex-start" component="div">
            <ListItemAvatar>
              <Avatar alt={option.user.first_name} src={ProfileImage} />
            </ListItemAvatar>
            <ListItemText
              primary={`${option.user.first_name} ${option.user.last_name}`}
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Specialized in {option.specialization}
                  </Typography>
                  {` - ${option.description}`}
                  {/* {" — Wish I could come, but I'm out of town this…"} */}
                </>
              }
            />
          </ListItem>
        )}
        noOptionsText="No users found..."
      />
    </div>
  );
}
