import React from 'react';
import ReactDOM from 'react-dom';
import './utils/i18n';
import './index.css';
import App from './App';
import './configurations/axiosConfig';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function  ** Important**
// to log results (for example: reportWebVitals(console.log))  ** Important**
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals  ** Important**
reportWebVitals();
