import React from 'react';
import { authRoles } from '../../store/auth';
import SubPagesConfig from './SubPages/SubPagesConfig';

const MyContentConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/mycontent',
      component: React.lazy(() => import('./MyContent')),
    },
    ...SubPagesConfig.routes,
  ],
};

export default MyContentConfig;
