import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { showMessage } from '../../messageSlice';
import history from '../../../configurations/@history';
import { startLoading1, clearLoading1 } from '../../loaderSlice';

const initialState = {
  success: false,
  userData: null,
  error: {
    username: null,
    password: null,
  },
};

const registerSlice = createSlice({
  name: 'auth/register',
  initialState,
  reducers: {
    registerSuccess: (state, action) => {
      state.success = true;
      state.userData = {
        username: action.payload.user.username,
        destination: action.payload.codeDeliveryDetails
          ? action.payload.codeDeliveryDetails.Destination
          : '',
      };
    },
    registerError: (state, action) => {
      state.success = false;
      state.error = action.payload;
      state.userData = {
        codeDeliveryDetails: null,
        user: null,
      };
    },
  },
  extraReducers: {},
});

export const { registerSuccess, registerError } = registerSlice.actions;

export const submitRegister =
  ({ name, password, email }) =>
  async (dispatch) => {
    dispatch(startLoading1());
    return Auth.signUp({
      username: email.trim().replace('@', '$'),
      password,
      attributes: { email, given_name: name },
    })
      .then((user) => {
        dispatch(clearLoading1());
        dispatch(registerSuccess({ ...user }));
        // history.push("/mail-confirm")
        dispatch(
          showMessage({
            message: `Registration is successful. We have sent a verification link to ${email}. Please verify your email to log in with your email.`,
            variant: 'success',
          })
        );
        history.push('/signin');
        return user;
      })
      .catch((error) => {
        dispatch(clearLoading1());
        dispatch(
          showMessage({
            message: 'Email id already exists. Please log in to continue',
            variant: 'error',
          })
        );
        dispatch(registerError(error));
      });
  };

export default registerSlice.reducer;
