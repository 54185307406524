import { amber, blue, green } from '@material-ui/core/colors';
import { Trans } from 'react-i18next';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../../store/messageSlice';

const useStyles = makeStyles((theme) => ({
  root: {},
  success: {
    backgroundColor: green[600],
    color: '#FFFFFF',
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark),
  },
  info: {
    backgroundColor: blue[600],
    color: '#FFFFFF',
  },
  warning: {
    backgroundColor: amber[600],
    color: '#FFFFFF',
  },
  snackbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  message: {
    margin: '0px 20px',
    padding: 0,
  },
}));

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info',
};

const Message = () => {
  const dispatch = useDispatch();
  const mesageState = useSelector((state) => state.message.state);
  const options = useSelector((state) => state.message.options);

  const classes = useStyles();

  return (
    <Snackbar
      {...options}
      open={mesageState}
      onClose={() => dispatch(hideMessage())}
      classes={{
        root: classes.root,
      }}
      ContentProps={{
        variant: 'body2',
        headlineMapping: {
          body1: 'div',
          body2: 'div',
        },
      }}
    >
      <SnackbarContent
        className={clsx(classes[options.variant])}
        message={
          <div className={classes.snackbarIcon}>
            {variantIcon[options.variant] && (
              <Icon color="inherit">{variantIcon[options.variant]}</Icon>
            )}
            <Typography className={classes.message}>
              <Trans>{options.message}</Trans>
            </Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default React.memo(Message);
