import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Fab from '@material-ui/core/Fab';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MenuItem from '@material-ui/core/MenuItem';
import GroupIcon from '@material-ui/icons/Group';
import Menu from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logoutUser } from '../../store/auth/store/userSlice';
import VideoUpload from '../VideoUpload';
import { showMessage } from '../../store/messageSlice';
import { setVideoUpload } from '../../store/Streaming';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  nav_root: {
    justifyContent: 'space-evenly',
  },
  offset: theme.mixins.toolbar,
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#f5f5f5',
    color: 'red',
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -20,
    left: 0,
    right: 0,
    textTransform: 'none',
    margin: '0 auto',
    '&.MuiFab-extended': {
      width: '180px',
      [theme.breakpoints.down('sm')]: {
        width: '20px',
      },
    },
    '&:hover': {
      backgroundColor: '#000',
    },
  },
  navLabel: {
    '&.Mui-selected': {
      fontSize: '0.7rem',
    },
  },
  btnText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const FooterLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const [value, setValue] = useState(pathname);
  const history = useHistory();
  const user = useSelector(({ auth }) => auth.user);
  const storeData = useSelector(({ Streaming }) => Streaming);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVideoUploadOpen = () => {
    if (user.loginStatus) {
      dispatch(setVideoUpload({ open: true, type: 'create', content: null }));
    } else {
      dispatch(
        showMessage({ message: 'Please login to upload', variant: 'error' })
      );
      history.push('/signin');
    }
  };

  return (
    <>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Fab
          color="primary"
          variant="extended"
          aria-label="add"
          aria-controls="fab-menu"
          aria-haspopup="true"
          onClick={handleVideoUploadOpen}
          className={classes.fabButton}
        >
          <AddIcon sx={{ mr: 1 }} />
          <span className={classes.btnText}>Upload Video</span>
        </Fab>

        {storeData.videoUpload.open && (
          <VideoUpload openVideoUpload={storeData.videoUpload.open} />
        )}
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className={classes.nav_root}
        >
          <BottomNavigationAction
            classes={{
              label: classes.navLabel,
            }}
            value="/"
            to="/"
            component={Link}
            label="Home"
            role="button"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            classes={{
              label: classes.navLabel,
            }}
            label="Search"
            icon={<SearchIcon />}
          />
          <BottomNavigationAction
            classes={{
              label: classes.navLabel,
            }}
            value="/mycontent/myvideos"
            to="/mycontent/myvideos"
            component={Link}
            label="My Content"
            role="button"
            icon={<OndemandVideoIcon />}
          />
          <BottomNavigationAction
            classes={{
              label: classes.navLabel,
            }}
            label="Menu"
            aria-controls="menu-footer"
            aria-haspopup="true"
            onClick={handleMenu}
            role="button"
            icon={<MenuIcon />}
          />
        </BottomNavigation>
        <Menu
          id="menu-footer"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          disableScrollLock
        >
          <MenuItem onClick={handleClose} to="/" component={Link} role="button">
            <ListItemIcon className="min-w-40">
              <Icon>home</Icon>
            </ListItemIcon>
            <ListItemText primary="Home" />
          </MenuItem>
          {user.loginStatus && (
            <MenuItem
              onClick={handleClose}
              to="/profile"
              component={Link}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
          )}
          {user.loginStatus && user.data.static_content_uploader && (
            <MenuItem
              onClick={handleClose}
              to="/settings"
              component={Link}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>settings</Icon>
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </MenuItem>
          )}
          {user.loginStatus ? (
            <MenuItem
              onClick={() => {
                dispatch(logoutUser());
                handleClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          ) : (
            <MenuItem
              onClick={handleClose}
              to="/signin"
              component={Link}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
          )}
          <MenuItem
            onClick={handleClose}
            to="/AboutUs"
            component={Link}
            role="button"
          >
            <ListItemIcon className="min-w-40">
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </MenuItem>
        </Menu>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
};

export default React.memo(FooterLayout);
