import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import NavbarFoldedToggleButton from './NavbarFoldedToggleButton';
import NavbarMobileToggleButton from './NavbarMobileToggleButton';

const useStyles = makeStyles({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    background:
      'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 'inherit',
    paddingBlock: 8,
    minHeight: 60,
  },
  logoDiv: {
    display: 'flex',
    flex: 1,
    marginBlock: 8,
  },
});

const NavbarLayout1 = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { className } = props;

  return (
    <div className={clsx(classes.main, className)}>
      <AppBar
        color="primary"
        position="static"
        elevation={0}
        className={classes.appBar}
      >
        <div className={classes.logoDiv}>{/* <Logo /> */}</div>

        <Hidden mdDown>
          <NavbarFoldedToggleButton className="w-40 h-40 p-0" />
        </Hidden>

        <Hidden lgUp>
          <NavbarMobileToggleButton className="w-40 h-40 p-0">
            <Icon>
              {theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}
            </Icon>
          </NavbarMobileToggleButton>
        </Hidden>
      </AppBar>

      <div className={clsx(classes.content)} />

      {/* <FuseScrollbars
        className={clsx(classes.content)}
        option={{ suppressScrollX: true }}
      >
        <UserNavbarHeader />

        <Navigation layout="vertical" />
      </FuseScrollbars> */}
    </div>
  );
};

export default React.memo(NavbarLayout1);
