import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: ['Montserrat'].join(','),
    fontWeightLight: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main, ** Important**
      main: '#E01010',
      // dark: will be calculated from palette.primary.main, ** Important**
      // contrastText: will be calculated to contrast with palette.primary.main, ** Important**
    },
    secondary: {
      light: '#0066FF',
      main: '#0044FF',
      // dark: will be calculated from palette.secondary.main, ** Important**
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiDivider: {
      backgroundColor: 'rgb(0 0 0 0)',
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontSize: '15px',
        padding: '0.7rem',
        fontWeight: 600,
        cursor: 'pointer',
      },
    },
  },
});
