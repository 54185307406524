import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Link } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
// import Chip from '@material-ui/core/Chip';
// import AddIcon from '@material-ui/icons/Add';
// import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDispatch, useSelector } from 'react-redux';
import CardMedia from '@material-ui/core/CardMedia';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import {
  fetchSignedURL,
  patchVideoContent,
  postStaticContent,
  postAiAnalyticsContent,
  getStaticContentItem,
  patchStaticContent,
  getSports,
  getStaticContentCats,
  setVideoUpload,
  setResetMyVideos,
  // setAiAnalytics,
  setResetAdminStaticContent,
  setResetStaticContent,
  getVideoReviewers,
  setResetMyAiAnalytics,
} from '../store/Streaming';
import { showMessage } from '../store/messageSlice';
import { setAlert } from '../store/backdropSlice';
import ReviewersAutocomplete from './ReviewersAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  a: {
    background: 'none!important',
    borderBottom: 'none!important',
  },
  coachBtn: {
    textTransform: 'none',
    DisableRipple: true,
    fontSize: '12px',
    color: '#E01010',
    // fontWeight: 400,
    marginTop: '3px',
  },
}));

export default function VideoUpload({ openVideoUpload }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const userData = useSelector(({ auth }) => auth.user);
  const storeData = useSelector(({ Streaming }) => Streaming);

  const [openProfile, setOpenProfile] = React.useState(false);

  const handleCoachClickOpen = () => {
    setOpenProfile(true);
  };
  const handleCoachClose = () => {
    setOpenProfile(false);
  };

  const [videoUploadData, setVideoUploadData] = React.useState({});

  React.useEffect(() => {
    setVideoUploadData({
      fileRef: null,
      filename:
        storeData.videoUpload.type === 'edit'
          ? storeData.videoUpload.content.content_name
          : null,
      videoFilePath:
        storeData.videoUpload.type === 'edit'
          ? storeData.videoUpload.content.path
          : null,
      content_name:
        storeData.videoUpload.type === 'edit'
          ? storeData.videoUpload.content.content_name
          : '',
      privacy: 'Public',
      sports: '1',
      category:
        storeData.videoUpload.type === 'edit'
          ? storeData.videoUpload.content.sport_skill_category
          : '',
      description:
        storeData.videoUpload.type === 'edit'
          ? storeData.videoUpload.content.description
          : '',
      reviewers: [],
      remove_reviewers: [],
      signedURLData: null,
      staticContentItem: null,
      staticContentCat: '',
      precedence: 1,
    });
  }, [storeData.videoUpload]);

  const [reviewersLoading, setReviewersLoading] = React.useState(true);
  const [defaultReviewers, setDefaultReviewers] = React.useState([]);
  React.useEffect(() => {
    let isMounted = true;
    setReviewersLoading(true);
    async function fetchVideoReviewers() {
      const response = await dispatch(
        getVideoReviewers(storeData.videoUpload.content.id)
      );
      setReviewersLoading(false);
      if (response && response.results) {
        setDefaultReviewers(response.results);
        setVideoUploadData((prevState) => ({
          ...prevState,
          remove_reviewers: response.results,
        }));
      }
    }
    async function fetchStaticContentItem() {
      const response = await dispatch(
        getStaticContentItem(storeData.videoUpload.content.id)
      );
      if (response && response.id) {
        setVideoUploadData((prevState) => ({
          ...prevState,
          precedence: response.precedence,
          staticContentCat: storeData.staticContentCats.find(
            (cat) => cat.id === response.static_content_categories
          ),
          staticContentItem: { ...response },
        }));
      }
    }
    if (storeData.videoUpload.type === 'edit' && isMounted) {
      fetchVideoReviewers();
      if (userData.data.static_content_uploader) {
        fetchStaticContentItem();
      }
    } else {
      setReviewersLoading(false);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, storeData.videoUpload, storeData.videoUpload.type]);

  function handleVideoUploadData(event, fieldName) {
    setVideoUploadData((preState) => ({
      ...preState,
      [`${fieldName}`]: event.target.value,
    }));
  }

  const [sportCategories, setSportCategories] = React.useState([]);
  React.useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      await dispatch(getSports());
    }
    if (storeData.sportsList.length === 0 && isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [dispatch, openVideoUpload, storeData.sportsList.length]);

  const [staticContentCats, setStaticContentCats] = React.useState([]);
  React.useEffect(() => {
    let isMounted = true;
    async function fetchStaticContentCats() {
      await dispatch(getStaticContentCats());
    }
    if (storeData.staticContentCats.length === 0 && isMounted) {
      fetchStaticContentCats();
    }
    setStaticContentCats(
      storeData.staticContentCats.filter((cat) => cat.code !== 'SCC-RU')
    );
    return () => {
      isMounted = false;
    };
  }, [dispatch, storeData.staticContentCats]);

  React.useEffect(() => {
    if (storeData.sportsList.length > 0) {
      setSportCategories(storeData.sportsList[0].sport_skill_category);
    }
  }, [storeData]);

  const [loading, setLoading] = React.useState(false);
  const [inputFile, setInputFile] = React.useState(videoUploadData.fileRef);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  const fileSelectRef = (ref) => {
    setInputFile(ref);
  };
  const handleFileSelect = () => {
    inputFile.click();
  };
  const selectedFile = () => {
    handleVideoUploadData(
      { target: { value: inputFile.files[0].name } },
      'filename'
    );
    handleVideoUploadData(
      { target: { value: URL.createObjectURL(inputFile.files[0]) } },
      'videoFilePath'
    );
    if (
      !userData.data.static_content_uploader &&
      inputFile.files[0].size > 209715200
    ) {
      dispatch(
        showMessage({
          message: 'Please upload a file less than 200MB',
          variant: 'error',
        })
      );
      handleVideoUploadData({ target: { value: null } }, 'filename');
    } else {
      dispatch(fetchSignedURL({ filename: inputFile.files[0].name }))
        .then((res) => {
          handleVideoUploadData({ target: { value: res } }, 'signedURLData');
        })
        .catch(() => {
          dispatch(
            showMessage({
              message: 'Sorry, there is some network issue. Please try again',
              variant: 'error',
            })
          );
        });
    }
  };
  const handleRemoveFile = () => {
    handleVideoUploadData({ target: { value: null } }, 'filename');
    handleVideoUploadData({ target: { value: null } }, 'signedURLData');
    inputFile.value = '';
  };

  // const [tags, setTags] = React.useState([]);
  // const handleTagDelete = (tagToDelete) => () => {
  //   setTags((chips) => chips.filter((chip) => chip !== tagToDelete));
  // };
  // const [newTag, setNewTag] = React.useState('');
  // const handleNewTagChange = (event) => {
  //   setNewTag(`${event.target.value}`);
  // };
  // const handleNewTagSubmit = (event) => {
  //   if (event) {
  //     event.preventDefault();
  //   }
  //   if (
  //     newTag.trim() !== '' &&
  //     tags.findIndex((tag) => tag === newTag) === -1
  //   ) {
  //     setTags((prevState) => [...prevState, newTag]);
  //   }
  //   setNewTag('');
  // };

  const handleReviewersChange = (reviewersList) => {
    setDefaultReviewers(reviewersList);
    handleVideoUploadData(
      { target: { value: reviewersList.map((reviewer) => reviewer.user.id) } },
      'reviewers'
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    // setTags([]);
    // setNewTag('');
    dispatch(setVideoUpload({ open: false, type: null, content: null }));
  };

  const handleSportChange = (event) => {
    handleVideoUploadData(event, 'sports');
    setSportCategories(
      storeData.sportsList.find((sport) => sport.id === event.target.value)
        .sport_skill_category
    );
  };

  const filterRemoveReviewers = () => {
    const removeReviewers = [];
    videoUploadData.remove_reviewers.forEach((reviewer) => {
      const record = defaultReviewers.find(
        (defaultReviewer) => defaultReviewer.user.id === reviewer.user.id
      );
      if (!record) {
        removeReviewers.push(reviewer.user.id);
      }
    });
    return removeReviewers;
  };

  const [checked, setChecked] = React.useState(false);
  const handleAiChange = () => {
    setChecked(!checked);
  };

  const handleSubmit = async () => {
    if (videoUploadData.signedURLData == null) {
      dispatch(
        showMessage({
          message: 'Please select a video to upload',
          variant: 'error',
        })
      );
      return;
    }
    setUploadProgress(0);
    setLoading(true);
    const session = await Auth.currentSession();
    axios({
      method: 'put',
      url: videoUploadData.signedURLData.url,
      data: inputFile.files[0],
      headers: {
        'x-amz-meta-cid': session.idToken.payload.sub,
      },
      onUploadProgress: (progressEvent) =>
        setUploadProgress(
          Math.round((100 * progressEvent.loaded) / progressEvent.total)
        ),
    })
      .then(() =>
        dispatch(
          patchVideoContent(videoUploadData.signedURLData.id, {
            content_name: videoUploadData.content_name,
            privacy: videoUploadData.privacy,
            description: videoUploadData.description,
            sport_skill_category: videoUploadData.category,
            users: videoUploadData.reviewers,
          })
        )
      )
      .then(() => {
        if (userData.data.static_content_uploader) {
          dispatch(
            postStaticContent({
              content: videoUploadData.signedURLData.id,
              precedence: videoUploadData.precedence,
              static_content_categories: videoUploadData.staticContentCat.id,
            })
          );
        }
      })
      .then(() => {
        if (checked === true) {
          dispatch(
            postAiAnalyticsContent({
              content: [videoUploadData.signedURLData.id],
            })
          );
        }
      })
      .then(() => {
        dispatch(
          setAlert({
            message:
              'Your video has been sucessfully uploaded. It will take few minutes to appear in MyContent section.',
            variant: 'success',
          })
        );
        dispatch(setResetMyVideos());
        dispatch(setResetMyAiAnalytics());
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          showMessage({
            message: 'Sorry, there is some network issue. Please try again',
            variant: 'error',
          })
        );
      });
  };

  const handleEdit = async () => {
    setLoading(true);
    dispatch(
      patchVideoContent(storeData.videoUpload.content.id, {
        content_name: videoUploadData.content_name,
        privacy: videoUploadData.privacy,
        description: videoUploadData.description,
        sport_skill_category: videoUploadData.category,
        users: videoUploadData.reviewers,
        remove_reviewers: filterRemoveReviewers(),
        disable: false,
      })
    )
      .then(() => {
        if (userData.data.static_content_uploader) {
          dispatch(
            patchStaticContent(videoUploadData.staticContentItem.id, {
              precedence: videoUploadData.precedence,
              static_content_categories: videoUploadData.staticContentCat.id,
            })
          );
        }
      })
      .then(() => {
        dispatch(
          showMessage({
            message: 'Video content has been updated.',
            variant: 'success',
          })
        );
        dispatch(setResetMyVideos());
        dispatch(setResetMyAiAnalytics());
        if (userData.data.static_content_uploader) {
          dispatch(setResetAdminStaticContent());
          dispatch(setResetStaticContent());
        }
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          showMessage({
            message: 'Sorry, there is some network issue. Please try again',
            variant: 'error',
          })
        );
      });
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        open={openVideoUpload}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid
            container
            justifyContent="flex-end"
            alignContent="flex-start"
            alignItems="baseline"
            spacing={0}
          >
            <Grid item xs={11}>
              <Typography variant="h6">
                <b>Upload Video</b>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <IconButton
                  aria-label="close"
                  aria-controls="responsive-dialog-title"
                  onClick={handleClose}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={0}>
            {storeData.videoUpload.type === 'create' ? (
              <Grid item>
                <Box
                  bgcolor="#dfdfdf"
                  borderRadius={10}
                  height={200}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  flexDirection="column"
                  mb={2}
                >
                  <input
                    id="myInput"
                    type="file"
                    accept="video/*"
                    ref={fileSelectRef}
                    hidden
                    onChange={selectedFile}
                  />
                  {videoUploadData.filename ? (
                    <>
                      <IconButton
                        aria-label="close"
                        aria-controls="responsive-dialog-title"
                        onClick={handleRemoveFile}
                      >
                        <CancelIcon color="primary" />
                      </IconButton>
                      <CardMedia
                        component="video"
                        className={classes.media}
                        image={videoUploadData.videoFilePath}
                        height={150}
                        controls
                      />
                    </>
                  ) : (
                    <>
                      <IconButton
                        aria-label="close"
                        aria-controls="responsive-dialog-title"
                        onClick={handleFileSelect}
                      >
                        <AddCircleIcon fontSize="large" color="primary" />
                      </IconButton>
                      <Box
                        fontSize={12}
                        fontWeight="bold"
                        color="text.disabled"
                      >
                        Upload Video
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs>
              <Box mx={1} my={1}>
                <TextField
                  label="Title"
                  placeholder=""
                  fullWidth
                  required
                  value={videoUploadData.content_name}
                  inputProps={{ style: { fontSize: 20, fontWeight: 'bold' } }}
                  onChange={(event) =>
                    handleVideoUploadData(event, 'content_name')
                  }
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Box mx={1} my={1}>
                <TextField
                  select
                  required
                  label="Privacy"
                  fullWidth
                  value={videoUploadData.privacy}
                  onChange={(event) => handleVideoUploadData(event, 'privacy')}
                  InputProps={{
                    readOnly: userData.data.static_content_uploader,
                  }}
                >
                  {['Public', 'Private'].map((option) => (
                    <MenuItem
                      key={['Public', 'Private'].indexOf(option)}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs>
              <Box mx={1} my={1}>
                <TextField
                  select
                  required
                  label="Sports"
                  fullWidth
                  name="sports"
                  value={videoUploadData.sports}
                  onChange={(event) => handleSportChange(event)}
                  InputProps={{
                    readOnly: true,
                  }}
                >
                  {storeData.sportsList.map((option) => (
                    <MenuItem
                      key={storeData.sportsList.indexOf(option)}
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs>
              <Box mx={1} my={1}>
                <TextField
                  select
                  required
                  label="Category"
                  fullWidth
                  value={videoUploadData.category}
                  onChange={(event) => handleVideoUploadData(event, 'category')}
                >
                  {sportCategories.map((option) => (
                    <MenuItem
                      key={sportCategories.indexOf(option)}
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            {/* <Grid item xs>
              <Box mx={1} my={1}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <LocalOfferOutlinedIcon
                    style={{ fontSize: 16 }}
                    color="primary"
                  />
                  <Box fontWeight="bold"> Tags </Box>
                </div>
                <Box display="flex" flexWrap="wrap">
                  {tags.map((tag) => (
                    <Chip
                      key={tags.indexOf(tag)}
                      size="small"
                      label={tag}
                      onDelete={handleTagDelete(tag)}
                      className={classes.chip}
                    />
                  ))}
                  <Box>
                    <form onSubmit={handleNewTagSubmit} autoComplete="off">
                      <TextField
                        id="newTag"
                        size="small"
                        value={newTag}
                        onChange={handleNewTagChange}
                        inputProps={{
                          style: { width: 100, fontSize: 12 },
                        }}
                      />
                    </form>
                  </Box>
                  <AddIcon
                    style={{ fontSize: 28, cursor: 'pointer' }}
                    color="primary"
                    onClick={handleNewTagSubmit}
                  />
                </Box>
              </Box>
            </Grid> */}
            <Grid item xs>
              <Box mx={1} my={1}>
                <TextField
                  id="description"
                  label="Description"
                  name="description"
                  placeholder="Give a short description"
                  multiline
                  rows={3}
                  value={videoUploadData.description}
                  onChange={(event) =>
                    handleVideoUploadData(event, 'description')
                  }
                  required
                  fullWidth
                />
              </Box>
            </Grid>
            {!userData.data.static_content_uploader && !reviewersLoading && (
              <Grid item xs>
                <Box mx={1} my={2}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <PermIdentityOutlinedIcon style={{ fontSize: 20 }} />
                    <Box fontWeight="bold"> Reviewers </Box>
                    <Box
                      fontSize={10}
                      fontWeight="fontWeightRegular"
                      style={{ marginLeft: 'auto', color: '#9a9a9a' }}
                    >
                      Type min 3 characters
                    </Box>
                  </div>
                  {!reviewersLoading && (
                    <Box mt={1} display="flex" flexWrap="wrap">
                      <ReviewersAutocomplete
                        reviewers={defaultReviewers}
                        handleReviewersChange={handleReviewersChange}
                      />
                    </Box>
                  )}
                  <div>
                    <Button
                      variant="text"
                      className={classes.coachBtn}
                      onClick={handleCoachClickOpen}
                      size="small"
                      startIcon={<InfoOutlinedIcon fontSize="inherit" />}
                    >
                      Click here to view coaches profile
                    </Button>
                    <Dialog
                      fullScreen={fullScreen}
                      fullWidth
                      maxWidth="md"
                      open={openProfile}
                      onClose={handleCoachClose}
                      aria-labelledby="responsive-dialog-title1"
                    >
                      <DialogTitle id="responsive-dialog-title1">
                        <Grid
                          container
                          justifyContent="flex-end"
                          alignContent="flex-start"
                          alignItems="baseline"
                          spacing={0}
                        >
                          <Grid item xs={11}>
                            <Typography variant="h6">
                              <b>CK Coach Profiles</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Box>
                              <IconButton
                                aria-label="close"
                                aria-controls="responsive-dialog-title"
                                onClick={handleCoachClose}
                              >
                                <CancelIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider />
                      </DialogTitle>
                      <DialogContent
                        style={{
                          height: '100vh',
                          padding: 0,
                          overflowY: 'hidden',
                        }}
                      >
                        <iframe
                          title="coach profiles"
                          src="https://www.sportskingdom.io/skcoaches"
                          width="100%"
                          height="100%"
                          style={{ border: 'none' }}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
          {userData.data.static_content_uploader && (
            <>
              <Grid item xs>
                <Box mx={1} my={1}>
                  <TextField
                    select
                    required
                    label="Static Content Category"
                    fullWidth
                    value={videoUploadData.staticContentCat}
                    onChange={(event) =>
                      handleVideoUploadData(event, 'staticContentCat')
                    }
                  >
                    {staticContentCats.map((option) => (
                      <MenuItem
                        key={staticContentCats.indexOf(option)}
                        value={option}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs>
                <Box mx={1} my={1}>
                  <TextField
                    select
                    required
                    label="precedence"
                    fullWidth
                    value={videoUploadData.precedence}
                    onChange={(event) =>
                      handleVideoUploadData(event, 'precedence')
                    }
                  >
                    {[...Array(20)]
                      .map((value, index) => index + 1)
                      .map((option) => (
                        <MenuItem key={option + 1} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs>
            <Box mx={1} my={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleAiChange}
                      color="primary"
                      size="small"
                      sx={{
                        '&.material-ui-checked': {
                          color: '#E01010',
                        },
                      }}
                    />
                  }
                  label="Enable AI Analysis for this video"
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            direction="column"
          >
            <Box
              fontSize={12}
              fontWeight="fontWeightRegular"
              fontStyle="normal"
              textAlign="center"
            >
              On upload, you agree to our{' '}
              <Link
                href="https://www.sportskingdom.io/crickingdom-ai-terms"
                target="_blank"
                rel="noreferrer"
                className={classes.a}
              >
                <Typography color="primary" variant="string">
                  video upload terms and conditions
                </Typography>
              </Link>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              storeData.videoUpload.type === 'create'
                ? handleSubmit
                : handleEdit
            }
            variant="contained"
            fullWidth
            color="primary"
            disabled={
              (storeData.videoUpload.type === 'create' &&
                (videoUploadData.content_name &&
                videoUploadData.category &&
                videoUploadData.description
                  ? loading
                  : 'disabled')) ||
              (userData.data.static_content_uploader &&
                (videoUploadData.content_name &&
                videoUploadData.category &&
                videoUploadData.description &&
                videoUploadData.staticContentCat
                  ? loading
                  : 'disabled'))
            }
          >
            {loading ? (
              <>
                <CircularProgress size={24} />{' '}
                {storeData.videoUpload.type === 'create' && (
                  <Box
                    mx={2}
                    fontSize={16}
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {uploadProgress}%
                  </Box>
                )}
              </>
            ) : (
              <>
                {storeData.videoUpload.type === 'edit'
                  ? 'Save content'
                  : 'Post my video'}
              </>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
