import React from 'react';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Theme from './Themes/Theme';
import awsconfig from './configurations/aws-exports';
import AppContext from './components/AppContext';
import routes from './configurations/routesConfig';
import history from './configurations/@history';
import store from './store';
import Layout from './components/layout/Layout';
import Authorization from './configurations/Authorization';
import Auth from './components/Auth';
import '@fontsource/montserrat';

const generateClassName = createGenerateClassName();
Amplify.configure(awsconfig);

// initialize Google Analytics on the root
ReactGA.initialize('UA-210701497-1');
createBrowserHistory(window);

history.listen((location) => {
  // to report page view
  ReactGA.pageview(location.pathname + location.search);
});

const App = () => (
  <AppContext.Provider value={{ routes }}>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={Theme}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Auth>
              <Router history={history}>
                <Authorization>
                  <Layout />
                </Authorization>
              </Router>
            </Auth>
          </MuiPickersUtilsProvider>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </AppContext.Provider>
);
export default App;
