import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { navbarToggleMobile } from '../../store/navbarSlice';

const NavbarMobileToggleButton = (props) => {
  const dispatch = useDispatch();

  const { className, children } = props;

  return (
    <IconButton
      className={className}
      onClick={() => dispatch(navbarToggleMobile())}
      color="inherit"
      disableRipple
    >
      {children}
    </IconButton>
  );
};

NavbarMobileToggleButton.defaultProps = {
  children: <Icon>menu</Icon>,
};

export default NavbarMobileToggleButton;
