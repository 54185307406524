import { createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { showMessage } from '../../messageSlice';
import { startLoading1, clearLoading1 } from '../../loaderSlice';
import { setUserData } from './userSlice';
import { fetchConsumer, fetchCoach } from '../../profile/profileSlice';

const fieldList = {
  displayName: '',
  photoURL: '',
  email: '',
  user_type: '',
  date_of_birth: null,
  gender: '',
  country: '',
  given_name: '',
  family_name: '',
  specialization: '',
  played_under: '',
  description: '',
  certification: '',
};

const initialState = {
  success: false,
  error: {
    code: null,
    message: null,
    name: null,
  },
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess: (state) => {
      state.success = true;
    },
    loginError: (state, action) => {
      state.success = false;
      state.error = action.payload;
    },
    loginDefault: (state) => {
      state.success = false;
      state.error = {
        code: null,
        message: null,
        name: null,
      };
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError, loginDefault } = loginSlice.actions;

export const submitLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(startLoading1());
    return Auth.signIn(email, password)
      .then(async (user) => {
        dispatch(clearLoading1());
        if (user && user.attributes.email_verified) {
          if (Number(user.attributes['custom:user_type'])) {
            const userextData =
              // eslint-disable-next-line no-nested-ternary
              Number(user.attributes['custom:user_type']) === 2
                ? await dispatch(fetchConsumer())
                : Number(user.attributes['custom:user_type']) === 6
                ? await dispatch(fetchCoach())
                : {};
            const role =
              // eslint-disable-next-line no-nested-ternary
              Number(user.attributes['custom:user_type']) === 2
                ? 'player'
                : Number(user.attributes['custom:user_type']) === 6
                ? 'coach'
                : 'user';
            const userData = {
              role: [role], // guest
              data: {
                ...fieldList,
                displayName: user.attributes.given_name || user.attributes.name,
                photoURL: '',
                email: user.attributes.email,
                given_name: user.attributes.given_name,
                family_name: user.attributes.family_name,
                gender: user.attributes.gender,
                date_of_birth: user.attributes.birthdate,
                country: user.attributes['custom:country'],
                user_type: Number(user.attributes['custom:user_type']),
                ...userextData,
              },
              redirectUrl: '/',
              loginStatus: true,
            };
            delete userData.data.user;
            delete userData.data.first_name;
            delete userData.data.last_name;
            dispatch(setUserData(userData));
            dispatch(loginSuccess());
          } else {
            const userData = {
              role: ['user'], // guest
              data: {
                ...fieldList,
                displayName: user.attributes.given_name || user.attributes.name,
                photoURL: '',
                email: user.attributes.email,
                given_name: user.attributes.given_name,
                family_name: user.attributes.family_name
                  ? user.attributes.family_name
                  : '',
              },
              redirectUrl: '/profile',
              loginStatus: true,
            };
            dispatch(setUserData(userData));
            dispatch(loginSuccess());
          }
          return user;
        }
        return null;
      })
      .catch((error) => {
        dispatch(clearLoading1());
        dispatch(
          showMessage({
            message:
              'Incorrect username or password or <br/> Please make sure to verify your email by clicking the link sent to your email',
            variant: 'error',
          })
        );
        dispatch(loginError(error));
      });
  };

export const federatedLogin = () => async (dispatch) =>
  Auth.currentAuthenticatedUser()
    .then((user) => {
      dispatch(loginSuccess());
      return user;
    })
    .catch((error) => {
      dispatch(
        showMessage({
          message: 'Sorry, there is some network issue. Please try again',
          variant: 'error',
        })
      );
      dispatch(loginError(error));
    });

export default loginSlice.reducer;
