import { createSlice } from '@reduxjs/toolkit';
import { startLoading1, clearLoading1 } from '../loaderSlice';
import { openBackdrop, clearBackdrop } from '../backdropSlice';
import {
  fetchSignedURLService,
  patchVideoContentService,
  postStaticContentService,
  postAiAnalyticsContentService,
  patchStaticContentService,
  getVideoReviewersService,
  GetReviewersService,
  GetSportsService,
  getStaticContentCatsService,
  getStaticContentService,
  getStaticContentItemService,
  getAdminStaticContentService,
  postAdminStaticContentService,
  getMyFavoritesContentService,
  getMyVideosContentService,
  getAiAnalyticsContentService,
  getMyReviewContentService,
  getVideoContentService,
  getRecommendedContentService,
  getPoseAnalyticsDataService,
  getPoseAnalyticsAnglesDataService,
  getAiStatusContentService,
  getVideoCookiesService,
  getSportSkillSetService,
  postReviewService,
  patchReviewService,
  deleteReviewService,
  getVideoReviewsService,
  getCoachDetailsService,
  getSkillSetService,
  getVideoCommentsService,
  postCommentService,
  editCommentService,
  deleteCommentService,
  getCommentLikesService,
  postCommentLikesService,
  setFavoriteService,
  unsetFavoriteService,
  getShortUrl,
} from '../../services/default/defaultService';
import { showMessage } from '../messageSlice';

const initialState = {
  sportsList: [],
  myFavorites: [],
  resetMyFavorites: false,
  myVideos: [],
  resetMyVideos: false,
  myReviews: [],
  myAiAnalytics: [],
  resetMyAiAnalytics: false,
  resetMyReviews: false,
  currnetPlayVideo: null,
  videoUpload: {
    open: false,
    type: null, // create or edit  ** Important**
    content: null,
  },
  resetAdminStaticContent: false,
  resetStaticContent: false,
  staticContentCats: [],
  adminSelectedContent: [],
};

const VideoSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setVideoUpload: (state, action) => {
      state.videoUpload = action.payload;
    },
    setCurrnetPlayVideo: (state, action) => {
      state.currnetPlayVideo = action.payload;
    },
    setStaticContentCats: (state, action) => {
      state.staticContentCats = action.payload;
    },
    setStaticContent: (state, action) => {
      state.staticContentCats = state.staticContentCats.map((category) => {
        if (category.id === action.payload.categoryId) {
          return { ...category, listLoaded: true, list: action.payload.list };
        }
        return category;
      });
    },
    setStaticContentInit: (state, action) => {
      state.staticContentCats = state.staticContentCats.map((category) => {
        if (category.id === action.payload.categoryId) {
          return { ...category, listInit: true };
        }
        return category;
      });
    },
    setAdminStaticContent: (state, action) => {
      state.staticContentCats = state.staticContentCats.map((category) => {
        if (category.id === action.payload.categoryId) {
          return {
            ...category,
            adminListLoaded: true,
            adminList: action.payload.list,
          };
        }
        return category;
      });
    },
    setAdminStaticContentInit: (state, action) => {
      state.staticContentCats = state.staticContentCats.map((category) => {
        if (category.id === action.payload.categoryId) {
          return { ...category, adminListInit: true };
        }
        return category;
      });
    },
    setSportsList: (state, action) => {
      state.sportsList = action.payload;
    },
    setMyFavorites: (state, action) => {
      state.myFavorites = action.payload;
    },
    setResetMyFavorites: (state) => {
      state.myFavorites = [];
      state.resetMyFavorites = !state.resetMyFavorites;
    },
    setMyVideos: (state, action) => {
      state.myVideos = action.payload;
    },
    setResetMyVideos: (state) => {
      state.myVideos = [];
      state.resetMyVideos = !state.resetMyVideos;
    },
    setMyAiAnalytics: (state, action) => {
      state.myAiAnalytics = action.payload;
    },
    setResetMyAiAnalytics: (state) => {
      state.myAiAnalytics = [];
      state.resetMyAiAnalytics = !state.resetMyAiAnalytics;
    },
    setMyReviews: (state, action) => {
      state.myReviews = action.payload;
    },
    setResetMyReviews: (state) => {
      state.myReviews = [];
      state.resetMyReviews = !state.resetMyReviews;
    },
    setResetAdminStaticContent: (state) => {
      state.staticContentCats = state.staticContentCats.map((category) => ({
        ...category,
        adminListLoaded: false,
        adminListInit: false,
      }));
      state.resetAdminStaticContent = !state.resetAdminStaticContent;
    },
    setResetStaticContent: (state) => {
      state.staticContentCats = state.staticContentCats.map((category) => ({
        ...category,
        listLoaded: false,
        listInit: false,
      }));
      state.resetStaticContent = !state.resetStaticContent;
    },
    setUpdateFavorite: (state, action) => {
      state.myVideos = state.myVideos.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, favorites: action.payload.favorites };
        }
        return item;
      });
      state.myReviews = state.myReviews.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, favorites: action.favorites };
        }
        return item;
      });
      state.myFavorites = state.myFavorites.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, favorites: action.favorites };
        }
        return item;
      });
      state.myAiAnalytics = state.myAiAnalytics.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, favorites: action.favorites };
        }
        return item;
      });
      state.staticContentCats = state.staticContentCats.map((category) => ({
        ...category,
        list: category.list.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, favorites: action.payload.favorites };
          }
          return item;
        }),
        adminList: category.adminList.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, favorites: action.payload.favorites };
          }
          return item;
        }),
      }));
      state.myFavorites = [];
      state.resetMyFavorites = !state.resetMyFavorites;
    },
    setUpdateVideoContent: (state, action) => {
      state.myVideos = state.myVideos.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.item };
        }
        return item;
      });
      state.myReviews = state.myReviews.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.item };
        }
        return item;
      });
      state.myFavorites = state.myFavorites.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.item };
        }
        return item;
      });
      state.myAiAnalytics = state.myAiAnalytics.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.item };
        }
        return item;
      });
      state.staticContentCats = state.staticContentCats.map((category) => ({
        ...category,
        list: category.list.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload.item };
          }
          return item;
        }),
        adminList: category.adminList.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload.item };
          }
          return item;
        }),
      }));
      state.myFavorites = [];
      state.resetMyFavorites = !state.resetMyFavorites;
    },
    setAdminSelectedContent: (state, action) => {
      if (action.payload.type === 'clear') {
        state.adminSelectedContent = [];
      } else if (
        state.adminSelectedContent.indexOf(action.payload.contentId) === -1
      ) {
        state.adminSelectedContent = [
          ...state.adminSelectedContent,
          action.payload.contentId,
        ];
      } else {
        state.adminSelectedContent = state.adminSelectedContent.filter(
          (obj) => obj !== action.payload.contentId
        );
      }
    },
  },
  extraReducers: {},
});

export const {
  setVideoUpload,
  setCurrnetPlayVideo,
  setStaticContentCats,
  setStaticContent,
  setStaticContentInit,
  setAdminStaticContent,
  setAdminStaticContentInit,
  setSportsList,
  setMyFavorites,
  setResetMyFavorites,
  setMyVideos,
  setResetMyVideos,
  setMyAiAnalytics,
  setResetMyAiAnalytics,
  setMyReviews,
  setResetMyReviews,
  setResetAdminStaticContent,
  setResetStaticContent,
  setUpdateFavorite,
  setUpdateVideoContent,
  setAdminSelectedContent,
} = VideoSlice.actions;

export const fetchSignedURL = (data) => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await fetchSignedURLService(data);
    if (response && response.status) {
      dispatch(clearLoading1());
      return response.data;
    }
    dispatch(clearLoading1());
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const patchVideoContent = (patchId, data) => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await patchVideoContentService(patchId, data);
    dispatch(clearLoading1());
    if (response.status) {
      dispatch(setCurrnetPlayVideo(response.data));
      dispatch(
        setUpdateVideoContent({ id: response.data.id, item: response.data })
      );
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const deleteVideoContent = (patchId, data) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await patchVideoContentService(patchId, data);
    dispatch(clearBackdrop());
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const postStaticContent = (data) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await postStaticContentService(data);
    if (response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const postAiAnalyticsContent = (data) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await postAiAnalyticsContentService(data);
    if (response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const patchStaticContent = (contentId, data) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await patchStaticContentService(contentId, data);
    if (response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getStaticContent = (categoryId) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await getStaticContentService(categoryId);
    dispatch(clearBackdrop());
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getStaticContentItem = (contentId) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await getStaticContentItemService(contentId);
    dispatch(clearBackdrop());
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getAdminStaticContent = (categoryId) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await getAdminStaticContentService(categoryId);
    dispatch(clearBackdrop());
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const postAdminStaticContent = (data) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await postAdminStaticContentService(data);
    if (response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getVideoReviewers = (contentId) => async (dispatch) => {
  try {
    const response = await getVideoReviewersService(contentId);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getReviewers = (searchParam) => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await GetReviewersService(searchParam);
    if (response && response.status) {
      dispatch(clearLoading1());
      return response.data;
    }
    dispatch(clearLoading1());
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getSports = () => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await GetSportsService();
    if (response && response.status) {
      dispatch(clearLoading1());
      if (response.data && response.data.results) {
        dispatch(setSportsList(response.data.results));
      }
      return response.data;
    }
    dispatch(clearLoading1());
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getStaticContentCats = () => async (dispatch) => {
  try {
    const response = await getStaticContentCatsService();
    if (response && response.status) {
      if (response.data && response.data.results) {
        response.data.results.forEach((cat) => {
          cat.list = [];
          cat.listInit = false;
          cat.adminList = [];
          cat.adminListInit = false;
        });
        dispatch(setStaticContentCats(response.data.results));
      }
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getRecommendedContent = (uid) => async (dispatch) => {
  try {
    const response = await getRecommendedContentService(uid);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getMyFavoritesContent = () => async (dispatch) => {
  try {
    const response = await getMyFavoritesContentService();
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getMyVideosContent = () => async (dispatch) => {
  try {
    const response = await getMyVideosContentService();
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getMyAiAnalyticsContent = () => async (dispatch) => {
  try {
    const response = await getAiAnalyticsContentService();
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getMyReviewContent = () => async (dispatch) => {
  try {
    const response = await getMyReviewContentService();
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getVideoContent = (uid) => async (dispatch) => {
  try {
    const response = await getVideoContentService(uid);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

// AI content API response
export const getAiStatusContent = (uid) => async () => {
  try {
    const response = await getAiStatusContentService(uid);
    if (response && response.status) {
      return response.data;
      // eslint-disable-next-line no-else-return
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return { status: false, error: { message: error.response.statusText } };
    }
  }
  return null;
};

export const getPoseAnalyticsData = (uid) => async () => {
  try {
    const response = await getPoseAnalyticsDataService(uid);
    if (response && response.status) {
      return response.data;
      // eslint-disable-next-line no-else-return
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { status: false, error: { message: error.response.statusText } };
    }
  }
  return null;
};

export const getPoseAnalyticsAnglesData = (uid) => async () => {
  try {
    const response = await getPoseAnalyticsAnglesDataService(uid);
    if (response && response.status) {
      return response.data;
      // eslint-disable-next-line no-else-return
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { status: false, error: { message: error.response.statusText } };
    }
  }
  return null;
};

export const getVideoCookies = (uid) => async (dispatch) => {
  dispatch(startLoading1());
  dispatch(openBackdrop());
  try {
    const response = await getVideoCookiesService(uid);
    if (response && response.status) {
      dispatch(clearBackdrop());
      dispatch(clearLoading1());
      return response.data;
    }
    dispatch(clearLoading1());
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getSportSkillSet = (sportSkillCategory) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await getSportSkillSetService(sportSkillCategory);
    if (response && response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const postReview = (data) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await postReviewService(data);
    if (response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const patchReview = (reviewId, data) => async (dispatch) => {
  try {
    const response = await patchReviewService(reviewId, data);
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const deleteReview = (reviewId) => async (dispatch) => {
  try {
    const response = await deleteReviewService(reviewId);
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getVideoReviews = (contentId) => async (dispatch) => {
  try {
    const response = await getVideoReviewsService(contentId);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getCoachDetails = (coachId) => async (dispatch) => {
  try {
    const response = await getCoachDetailsService(coachId);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getSkillSet = (skillSetId) => async (dispatch) => {
  dispatch(openBackdrop());
  try {
    const response = await getSkillSetService(skillSetId);
    if (response && response.status) {
      dispatch(clearBackdrop());
      return response.data;
    }
    dispatch(clearBackdrop());
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getVideoComments = (contentId) => async (dispatch) => {
  try {
    const response = await getVideoCommentsService(contentId);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const postComment = (data) => async (dispatch) => {
  try {
    const response = await postCommentService(data);
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const editComment = (commentId, data) => async (dispatch) => {
  try {
    const response = await editCommentService(commentId, data);
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const deleteComment = (commentId) => async (dispatch) => {
  try {
    const response = await deleteCommentService(commentId);
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const getCommentLikes = (commentId) => async (dispatch) => {
  try {
    const response = await getCommentLikesService(commentId);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const postCommentLikes = (data) => async (dispatch) => {
  try {
    dispatch(openBackdrop());
    const response = await postCommentLikesService(data);
    dispatch(clearBackdrop());
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const setFavorite = (data) => async (dispatch) => {
  try {
    dispatch(openBackdrop());
    const response = await setFavoriteService(data);
    dispatch(clearBackdrop());
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const unsetFavorite = (contentId) => async (dispatch) => {
  try {
    dispatch(openBackdrop());
    const response = await unsetFavoriteService(contentId);
    dispatch(clearBackdrop());
    if (response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(clearBackdrop());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const setShortUrl = (Url) => async (dispatch) => {
  try {
    const response = await getShortUrl(Url);
    if (response && response.status) {
      return response.data;
    }
    return null;
  } catch (error) {
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export default VideoSlice.reducer;
