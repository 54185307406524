const settingsConfig = {
  layout: {
    style: 'layout1',
    config: {
      navbar: {
        display: true,
        folded: false,
        position: 'left',
      },
      toolbar: {
        display: true,
        style: 'fixed',
        position: 'below',
      },
      footer: {
        display: true,
        style: 'fixed',
        position: 'below',
      },
      leftSidePanel: {
        display: true,
      },
      rightSidePanel: {
        display: true,
      },
    },
  },
};

export default settingsConfig;
