import React from 'react';
import { authRoles } from '../../../store/auth';

const MyContentSubPagesConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/mycontent/favorites',
      component: React.lazy(() => import('./Favorites')),
    },
    {
      path: '/mycontent/myvideos',
      component: React.lazy(() => import('./MyVideos')),
    },
    {
      path: '/mycontent/review',
      component: React.lazy(() => import('./Review')),
    },
    {
      path: '/mycontent/aianalytics',
      component: React.lazy(() => import('./AiAnalytics')),
    },
  ],
};

export default MyContentSubPagesConfig;
