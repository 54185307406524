import React from 'react';
import Utils from '../utils';
import LoginConfig from '../pages/login/LoginConfig';
import SignupConfig from '../pages/signup/SignupConfig';
import ProfileConfig from '../pages/profile/ProfileConfig';
import PlayerConfig from '../pages/player/PlayerConfig';
import MyContentConfig from '../pages/MyContent/MyContentConfig';
import SettingsConfig from '../pages/Settings/SettingsConfig';
import AboutUsConfig from '../pages/aboutUs/AboutUsConfig';
import GettingStartedConfig from '../pages/gettingStarted/GettingStartedConfig';
import RecentUploadsConfig from '../pages/recentUploads/RecentUploadsConfig';

const routeConfigs = [
  LoginConfig,
  SignupConfig,
  ProfileConfig,
  PlayerConfig,
  MyContentConfig,
  SettingsConfig,
  AboutUsConfig,
  GettingStartedConfig,
  RecentUploadsConfig,
];

const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: React.lazy(() => import('../pages/landing/Landing')),
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: true,
          },
          footer: {
            display: true,
          },
          leftSidePanel: {
            display: false,
          },
          rightSidePanel: {
            display: false,
          },
        },
      },
    },
  },
];

export default routes;
