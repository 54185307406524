import { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../store/auth/store/userSlice';
import NavbarMobileToggleButton from './NavbarMobileToggleButton';
import ProfileImage from '../../assets/profile.png';
import Logo from '../../assets/Logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 100,
    margin: 8,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  textVariant: {
    textTransform: 'none',
    fontWeight: 100,
  },
}));

const ToolbarLayout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const config = useSelector((state) => state.settings.current.layout.config);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const location = useLocation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {config.navbar.display && (
            <Hidden lgUp>
              <NavbarMobileToggleButton className="w-48 h-48 p-0" />
            </Hidden>
          )}
          <div className={classes.title}>
            <Link to="/">
              <img src={Logo} alt="logo" className={classes.logo} />
            </Link>
          </div>
          {!user.loginStatus ? (
            <>
              <Button
                color="inherit"
                component={Link}
                variant="outlined"
                className={classes.textVariant}
                to="/signin"
              >
                Login / Signup
              </Button>
              {/* <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="secondary" variant="dot">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
            </>
          ) : (
            <div>
              <Typography
                component="span"
                className="normal-case font-bold flex"
              >
                {user.data.given_name} {user.data.family_name}
              </Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {user.data.photoURL ? (
                  <Avatar
                    className="mx-4"
                    alt="user photo"
                    src={user.data.photoURL}
                  />
                ) : (
                  <Avatar
                    className={classes.small}
                    alt="Profile pic"
                    src={ProfileImage}
                  />
                )}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                disableScrollLock
              >
                <MenuItem
                  onClick={handleClose}
                  to="/"
                  component={Link}
                  role="button"
                >
                  <ListItemIcon className="min-w-40">
                    <Icon>home</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  to="/profile"
                  component={Link}
                  role="button"
                >
                  <ListItemIcon className="min-w-40">
                    <Icon>account_circle</Icon>
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(logoutUser());
                    handleClose();
                  }}
                >
                  <ListItemIcon className="min-w-40">
                    <Icon>exit_to_app</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default memo(ToolbarLayout);
