import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  startLoading1,
  clearLoading1,
  startLoading2,
  clearLoading2,
} from '../loaderSlice';
import {
  fetchConsumerService,
  fetchUserService,
  fetchAllCountriesService,
  fetchCoachService,
  updateConsumerService,
  updateCoachService,
} from '../../services/default/defaultService';
import { showMessage } from '../messageSlice';

export const getCountriesList = createAsyncThunk(
  'profile/getCountriesList',
  async (target, { dispatch }) => {
    dispatch(startLoading2());
    try {
      const response = await fetchAllCountriesService();
      if (response.status && typeof response === 'object') {
        dispatch(clearLoading2());
        const data = [];
        Object.keys(response.data).map((key) =>
          data.push({
            text: response.data[key],
            value: key,
          })
        );
        return data;
      }
      dispatch(clearLoading2());
      return [];
    } catch (error) {
      dispatch(clearLoading2());
      dispatch(
        showMessage({
          message: 'Sorry, there is some network issue. Please try again',
          variant: 'error',
        })
      );
      return [];
    }
  }
);

const initialState = {
  countries: [],
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [getCountriesList.fulfilled]: (state, action) => ({
      ...state,
      countries: action.payload,
    }),
  },
});

export const fetchUser = () => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await fetchUserService();
    if (response.status) {
      dispatch(clearLoading1());
      return response.data;
    }
    dispatch(clearLoading1());
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const fetchConsumer = () => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await fetchConsumerService();
    if (response.status) {
      dispatch(clearLoading1());
      return response.data;
    }
    dispatch(clearLoading1());
    if (response.error) {
      dispatch(
        showMessage({ message: response.error.message, variant: 'error' })
      );
    } else {
      dispatch(showMessage({ message: response.detail, variant: 'error' }));
    }
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const fetchCoach = () => async (dispatch) => {
  dispatch(startLoading1());
  try {
    const response = await fetchCoachService();
    if (response.status) {
      dispatch(clearLoading1());
      return response.data;
    }
    dispatch(clearLoading1());
    if (response.error) {
      dispatch(
        showMessage({ message: response.error.message, variant: 'error' })
      );
    } else {
      dispatch(showMessage({ message: response.detail, variant: 'error' }));
    }
    return null;
  } catch (error) {
    dispatch(clearLoading1());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const updateConsumer = (data) => async (dispatch) => {
  dispatch(startLoading2());
  try {
    const response = await updateConsumerService(data);
    if (response.status) {
      dispatch(clearLoading2());
      dispatch(
        showMessage({ message: 'Player Data Updated', variant: 'success' })
      );
      return response.data;
    }
    dispatch(clearLoading2());
    if (response.error) {
      dispatch(
        showMessage({ message: response.error.message, variant: 'error' })
      );
    } else {
      dispatch(showMessage({ message: response.detail, variant: 'error' }));
    }
    return null;
  } catch (error) {
    dispatch(clearLoading2());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export const updateCoach = (data) => async (dispatch) => {
  dispatch(startLoading2());
  try {
    const response = await updateCoachService(data);
    if (response.status) {
      dispatch(clearLoading2());
      dispatch(
        showMessage({ message: 'Coach Data Updated', variant: 'success' })
      );
      return response.data;
    }
    dispatch(clearLoading2());
    if (response.error) {
      dispatch(
        showMessage({ message: response.error.message, variant: 'error' })
      );
    } else {
      dispatch(showMessage({ message: response.detail, variant: 'error' }));
    }
    return null;
  } catch (error) {
    dispatch(clearLoading2());
    dispatch(
      showMessage({
        message: 'Sorry, there is some network issue. Please try again',
        variant: 'error',
      })
    );
    return null;
  }
};

export default profileSlice.reducer;
