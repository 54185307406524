/**
 * Authorization Roles
 */
const authRoles = {
  coach: ['coach'],
  player: ['coach', 'player'],
  user: ['coach', 'player', 'user'],
  onlyGuest: [],
};

export default authRoles;
