import axios from 'axios';
import { Auth } from 'aws-amplify';
import axiosConfig from '../../configurations/axiosConfig';

const handleResponse = (error) => {
  if (
    error.response &&
    (error.response.status === 500 ||
      error.response.status === 400 ||
      error.response.status === 401 ||
      error.response.status === 422)
  ) {
    return { status: false, error: { message: error.response.statusText } };
  }
  return error.response && error.response.data;
};

const checkAuth = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
};

export const fetchUserService = () =>
  axiosConfig
    .get(`/api/v1/web/user`)
    .then((response) => ({ status: true, data: response.data }))
    .catch(handleResponse);

export const fetchAllCountriesService = () =>
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/web/get-all-countries`)
    .then((response) => ({ status: true, data: response.data }))
    .catch(handleResponse);

export const fetchConsumerService = () =>
  axiosConfig
    .get(`/api/v1/web/consumer`)
    .then((response) => ({ status: true, data: response.data }))
    .catch(handleResponse);

export const fetchCoachService = () =>
  axiosConfig
    .get(`/api/v1/web/coach`)
    .then((response) => ({ status: true, data: response.data }))
    .catch(handleResponse);

export const updateConsumerService = (data) =>
  axiosConfig
    .post(`/api/v1/web/consumer`, data)
    .then((response) => ({ status: true, data: response.data }))
    .catch(handleResponse);

export const updateCoachService = (data) =>
  axiosConfig
    .post(`/api/v1/web/coach`, data)
    .then((response) => ({ status: true, data: response.data }))
    .catch(handleResponse);

export const fetchSignedURLService = (data) =>
  axiosConfig
    .get(`/api/v1/cs/get-upload-url?file_name=${data.filename}&privacy=private`)
    .then((response) => ({ status: true, data: response.data }));

export const patchVideoContentService = (patchId, data) =>
  axiosConfig
    .patch(`/api/v1/web/content/${patchId}`, data)
    .then((response) => ({ status: true, data: response.data }));

export const postStaticContentService = (data) =>
  axiosConfig
    .post(`/api/v1/web/static-content`, data)
    .then((response) => ({ status: true, data: response.data }));

export const patchStaticContentService = (contentId, data) =>
  axiosConfig
    .patch(`/api/v1/web/static-content/${contentId}`, data)
    .then((response) => ({ status: true, data: response.data }));

export const getVideoReviewersService = (contentId) =>
  axiosConfig
    .get(`/api/v1/web/reviewers?content=${contentId}`)
    .then((response) => ({ status: true, data: response.data }));

export const GetReviewersService = (searchParam) =>
  axiosConfig
    .get(`/api/v1/web/get-all-coaches?search=${searchParam}`)
    .then((response) => ({ status: true, data: response.data }));

export const GetSportsService = () =>
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/web/sport`)
    .then((response) => ({ status: true, data: response.data }));

export const getStaticContentCatsService = () =>
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/web/static-content-categories`
    )
    .then((response) => ({ status: true, data: response.data }));

export const getStaticContentService = (categoryId) =>
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/cs/static-content?static_content_categories=${categoryId}`
    )
    .then((response) => ({ status: true, data: response.data }));

export const getRecommendedContentService = async (uid) => {
  const authCheck = await checkAuth();
  if (authCheck) {
    return axiosConfig
      .get(`/api/v1/web/content-recommendation?uid=${uid}`)
      .then((response) => ({ status: true, data: response.data }));
  }
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/web/content-recommendation?uid=${uid}`
    )
    .then((response) => ({ status: true, data: response.data }));
};

export const getStaticContentItemService = (contentId) =>
  axiosConfig
    .get(`/api/v1/web/static-content?content=${contentId}`)
    .then((response) => ({ status: true, data: response.data }));

export const getAdminStaticContentService = (categoryId) =>
  axiosConfig
    .get(
      `/api/v1/cs/static-content-uploader-list?static_content_categories=${categoryId}`
    )
    .then((response) => ({ status: true, data: response.data }));

export const postAdminStaticContentService = (data) =>
  axiosConfig
    .post(`/api/v1/cs/static-content-uploader-list`, data)
    .then((response) => ({ status: true, data: response.data }));

export const getMyFavoritesContentService = () =>
  axiosConfig
    .get(`/api/v1/web/favorites`)
    .then((response) => ({ status: true, data: response.data }));

export const getMyVideosContentService = () =>
  axiosConfig
    .get(`/api/v1/cs/get-my-content`)
    .then((response) => ({ status: true, data: response.data }));

// Ai-Analytics get
export const getAiAnalyticsContentService = () =>
  axiosConfig
    .get(`/api/v1/web/video-analysis`)
    .then((response) => ({ status: true, data: response.data }));

// Ai-Analytics set/post
export const postAiAnalyticsContentService = (data) =>
  axiosConfig
    .post(`/api/v1/web/video-analysis`, data)
    .then((response) => ({ status: true, data: response.data }));

export const getMyReviewContentService = () =>
  axiosConfig
    .get(`/api/v1/cs/review-content`)
    .then((response) => ({ status: true, data: response.data }));

export const getVideoContentService = async (uid) => {
  const authCheck = await checkAuth();
  if (authCheck) {
    return axiosConfig
      .get(`/api/v1/cs/content/${uid}`)
      .then((response) => ({ status: true, data: response.data }));
  }
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/cs/content/${uid}`)
    .then((response) => ({ status: true, data: response.data }));
};

// GET AI-content API
export const getAiStatusContentService = async (uid) => {
  const authCheck = await checkAuth();
  if (authCheck) {
    return axiosConfig
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/web/ai-content/${uid}`)
      .then((response) => ({
        status: true,
        data: response.data,
      }))
      .catch((error) => {
        if (error.response) {
          return error.response.status;
        }
        return null;
      });
  }
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/web/ai-content/${uid}`)
    .then((response) => ({
      status: true,
      data: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        return error.response.status;
      }
      return null;
    });
};

export const getPoseAnalyticsDataService = async (uid) => {
  const authCheck = await checkAuth();
  const session = await Auth.currentSession();
  if (authCheck) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/cs/get-pose-analytics-data/${session.idToken.payload.sub}/${uid}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
        }
      )
      .then((response) => ({
        status: true,
        data: response.data,
      }))
      .catch((error) => {
        if (error.response) {
          return error.response.status;
        }
        return null;
      });
  }
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/cs/get-pose-analytics-data/${session.idToken.payload.sub}/${uid}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
      }
    )
    .then((response) => ({
      status: true,
      data: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        return error.response.status;
      }
      return null;
    });
};

// get-pose-analytics-angles-data
export const getPoseAnalyticsAnglesDataService = async (uid) => {
  const authCheck = await checkAuth();
  const session = await Auth.currentSession();
  if (authCheck) {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/cs/get-pose-analytics-angles-data/${session.idToken.payload.sub}/${uid}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
        }
      )
      .then((response) => ({
        status: true,
        data: response.data,
      }))
      .catch((error) => {
        if (error.response) {
          return error.response.status;
        }
        return null;
      });
  }
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/cs/get-pose-analytics-angles-data/${session.idToken.payload.sub}/${uid}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
      }
    )
    .then((response) => ({
      status: true,
      data: response.data,
    }))
    .catch((error) => {
      if (error.response) {
        return error.response.status;
      }
      return null;
    });
};

export const getVideoCookiesService = async (uid) => {
  const authCheck = await checkAuth();
  if (authCheck) {
    const session = await Auth.currentSession();
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/cs/get-cookies/${uid}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${session.idToken.jwtToken}`,
        },
      })
      .then((response) => ({ status: true, data: response.data }));
  }
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/cs/get-cookies/${uid}`, {
      withCredentials: true,
    })
    .then((response) => ({ status: true, data: response.data }));
};

export const getSportSkillSetService = (sportSkillCategory) =>
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/web/sport-skill-category/${sportSkillCategory}`
    )
    .then((response) => ({ status: true, data: response.data }));

export const postReviewService = (data) =>
  axiosConfig
    .post(`/api/v1/web/review`, data)
    .then((response) => ({ status: true, data: response.data }));

export const patchReviewService = (reviewId, data) =>
  axiosConfig
    .patch(`/api/v1/web/review/${reviewId}`, data)
    .then((response) => ({ status: true, data: response.data }));

export const deleteReviewService = (reviewId) =>
  axiosConfig
    .patch(`/api/v1/web/review/${reviewId}`, { disable: true })
    .then((response) => ({ status: true, data: response.data }));

export const getVideoReviewsService = async (contentId) => {
  const authCheck = await checkAuth();
  if (authCheck) {
    return axiosConfig
      .get(`/api/v1/web/review?content_id=${contentId}`)
      .then((response) => ({ status: true, data: response.data }));
  }
  // return axios // enable for public reviews  ** Important**
  //   .get(
  //     `${process.env.REACT_APP_BASE_URL}/api/v1/web/review?content_id=${contentId}`
  //   )
  //   .then((response) => ({ status: true, data: response.data }));
  return null;
};

export const getCoachDetailsService = (coachId) =>
  axiosConfig
    .get(`/api/v1/web/get-all-coaches/${coachId}`)
    .then((response) => ({ status: true, data: response.data }));

export const getSkillSetService = (skillSetId) =>
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/web/sport-skill-set/${skillSetId}`
    )
    .then((response) => ({ status: true, data: response.data }));

export const getVideoCommentsService = async (contentId) => {
  const authCheck = await checkAuth();
  if (authCheck) {
    return axiosConfig
      .get(`/api/v1/web/comment?content_id=${contentId}`)
      .then((response) => ({ status: true, data: response.data }));
  }
  // return axios //  enable for public comments  ** Important**
  //   .get(
  //     `${process.env.REACT_APP_BASE_URL}/api/v1/web/comment?content_id=${contentId}`
  //   )
  //   .then((response) => ({ status: true, data: response.data }));
  return null;
};

export const postCommentService = (data) =>
  axiosConfig
    .post(`/api/v1/web/comment`, data)
    .then((response) => ({ status: true, data: response.data }));

export const editCommentService = (commentId, data) =>
  axiosConfig
    .patch(`/api/v1/web/comment/${commentId}`, data)
    .then((response) => ({ status: true, data: response.data }));

export const deleteCommentService = (commentId) =>
  axiosConfig
    .patch(`/api/v1/web/comment/${commentId}`, { disable: true })
    .then((response) => ({ status: true, data: response.data }));

export const getCommentLikesService = (commentId) =>
  axiosConfig
    .get(`/api/v1/web/comment-reaction?comment=${commentId}`)
    .then((response) => ({ status: true, data: response.data }));

export const postCommentLikesService = (data) =>
  axiosConfig
    .post(`/api/v1/web/comment-reaction`, data)
    .then((response) => ({ status: true, data: response.data }));

export const setFavoriteService = (data) =>
  axiosConfig
    .post(`/api/v1/web/favorites`, data)
    .then((response) => ({ status: true, data: response.data }));

export const unsetFavoriteService = (contentId) =>
  axiosConfig
    .delete(`/api/v1/web/favorites?content=${contentId}`)
    .then((response) => ({ status: true, data: response.data }));

export const getShortUrl = (Url) =>
  axios
    .get(`https://api.shrtco.de/v2/shorten?url=${Url}`)
    .then((response) => ({ status: true, data: response }));
